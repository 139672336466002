import { Button } from "@customerPortal/components/ui/button";
import { Input } from "@customerPortal/components/ui/input";
import logo from "@customerPortal/assets/octocom-logo.svg";
import background from "@customerPortal/assets/cali-demo-bg.jpeg";
import caliPhysicsLogo from "@customerPortal/assets/california-phys-logo.png";
import { createFileRoute } from "@tanstack/react-router";
import { useEffect, useState } from "react";

export const Route = createFileRoute("/demo/california-psychics")({
  component: CaliPsychicsDemo,
});

export default function CaliPsychicsDemo() {
  const [inputValue, setInputValue] = useState("");

  const handleQuestionClick = async (
    text: string,
    e: React.MouseEvent,
    shouldClearInput = false,
  ) => {
    e.preventDefault();

    if (text === "") {
      return;
    }

    const openChat = new CustomEvent("openChatEvent", {
      detail: {
        type: "OPEN_OCTOCOM",
      },
    });

    const sendMessage = new CustomEvent("sendChatMessageEvent", {
      detail: { content: text },
    });

    console.log("shouldClearInput", shouldClearInput);

    document.dispatchEvent(openChat);
    await new Promise((resolve) => setTimeout(resolve, 500));
    document.dispatchEvent(sendMessage);

    if (shouldClearInput) {
      setInputValue("");
    }
  };

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.octocom.ai/chatbot/bundle.js";
    script.id = "octocom-script";
    script.setAttribute(
      "data-config-id",
      "060379bd-9022-4ddd-bd6a-f2c77035003e",
    );
    script.setAttribute("data-open-window", "false");
    script.setAttribute("referrerpolicy", "no-referrer-when-downgrade");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="h-screen">
      <div
        className="h-full overflow-hidden bg-white bg-cover bg-center bg-no-repeat"
        style={{ backgroundImage: `url(${background})` }}
      >
        <div className="m-4">
          <img src={logo} alt="Logo" className="mt-1 h-10 w-10" />
        </div>
        <div className="container relative mx-auto flex h-full items-center justify-center overflow-hidden px-4">
          <header className="mb-16 w-full py-8 ">
            <div className="flex flex-col items-center justify-between">
              {/* Title Section */}
              <div className="absolute top-0 flex w-full items-center justify-center">
                <img src={caliPhysicsLogo} alt="Logo" className="h-15" />
              </div>
              <div className="mx-auto text-center">
                {/* Featured Questions */}
                <div className="mt-8 flex flex-wrap justify-center gap-4">
                  {[
                    { text: "How does it work?" },
                    { text: "How much does it cost?" },
                    { text: "Are psychic readings accurate?" },
                  ].map((item, index) => (
                    <a
                      key={index}
                      onClick={(e) => void handleQuestionClick(item.text, e)}
                      className="flex items-center gap-2 rounded-full bg-gray-100 px-3 py-2 hover:bg-gray-200"
                    >
                      <img
                        src="https://ww2-secure.justanswer.com/static/fe/re-kv-page-home-page/Compressed/search.svg"
                        width={16}
                        height={16}
                        className="h-4 w-4 flex-shrink-0"
                        alt=""
                      />
                      <span className="whitespace-nowrap text-sm text-gray-700">
                        {item.text}
                      </span>
                    </a>
                  ))}
                </div>

                {/* Question Form */}
                <form className="mx-auto mt-6 w-[800px] max-w-4xl">
                  <div className="rounded-lg bg-gray-100 p-4">
                    <div className="relative">
                      <Input
                        placeholder="What can we help with today?"
                        className="bg-gray-200 pr-32"
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                      />
                      <Button
                        className="absolute right-0 top-0 h-full rounded-l-none bg-blue-600 hover:bg-blue-700"
                        onClick={(e) =>
                          void handleQuestionClick(inputValue, e, true)
                        }
                      >
                        <img
                          src="https://ww2-secure.justanswer.com/static/fe/re-kv-page-home-page/Compressed/start-btn-img.svg"
                          width={16}
                          height={16}
                          alt="Start chat button"
                          className="mr-2 h-4 w-4"
                        />
                        Start chat
                      </Button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </header>
        </div>
      </div>
    </div>
  );
}
